import React from "react"
import { useMutation, useQuery } from "@apollo/client"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import Switch from "@material-ui/core/Switch"
import { useStyles } from "./styles-maint"
import { FormButtonSpinnerBase } from "../../components/Form"
import { ButtonSmallBase } from "../../components/Button"
import { ADMIN_SCORE_OPM_POOL_MUTATION, ADMIN_DELETE_POOL_PICKS_MUTATION, ADMIN_GET_POOL_MEMBERS, ADMIN_SQL_QUERY } from "../queries"
import Grid from "@material-ui/core/Grid"
import Divider from "@material-ui/core/Divider"
import LoadingView from "../components/LoadingView"
import Link from "../../components/Link"
import { fromGlobalId } from "../../../common/global-ids"
import constants from "../../../common/constants"

const OPMPool = ({ pool, setSnackBarSuccessMessage, setSnackBarErrorMessage }) => {
  const classes = useStyles()

  const [scoreFullSeason, setScoreFullSeason] = React.useState(false)
  const [AdminScoreOPMPoolMutation, { loading: loadingScorePool }] = useMutation(ADMIN_SCORE_OPM_POOL_MUTATION)
  const [AdminDeletePoolPicksMutation, { loading: loadingDeletePicks }] = useMutation(ADMIN_DELETE_POOL_PICKS_MUTATION)
  const poolMembersDataQuery = useQuery(ADMIN_GET_POOL_MEMBERS, {
    variables: { poolId: pool.id },
    fetchPolicy: "network-only",
    context: {
      headers: {
        "x-admin-request": true,
      },
    },
  })

  const poolId = fromGlobalId(pool.id).id

  const statement = `SELECT User.id as id, userLogin FROM User INNER JOIN Member on Member.userId = User.id where Member.poolId = ${poolId}`
  const poolMembersUserLoginAdminQuery = useQuery(ADMIN_SQL_QUERY, {
    variables: {
      statement,
    },
    fetchPolicy: "network-only",
  })

  const scorePool = async (poolId: string, scoreFullSeason: boolean) => {
    try {
      AdminScoreOPMPoolMutation({
        variables: { poolId, scoreFullSeason },
      })
        .then((result) => {
          setSnackBarSuccessMessage(result.data?.adminScoreOPMPool)
        })
        .catch((error) => {
          setSnackBarErrorMessage(`Received error from mutation when trying to score opm pool ${error.message}`)
        })
    } catch (error: any) {
      setSnackBarErrorMessage(`Received error when trying to score opm pool ${error.message}`)
    }
  }

  const deletePicks = async (poolId: string) => {
    try {
      AdminDeletePoolPicksMutation({
        variables: { poolId },
      })
        .then((result) => {
          setSnackBarSuccessMessage(result.data?.adminDeletePoolPicks)
        })
        .catch((error) => {
          setSnackBarErrorMessage(`Received error from mutation when trying to delete picks for opm pool ${error.message}`)
        })
    } catch (error: any) {
      setSnackBarErrorMessage(`Received error when trying to delete picks for opm pool ${error.message}`)
    }
  }

  // const possesUser = async ({ id }) => {
  //   try {
  //     const response = await fetch(`/admin/posses/user?id=${id}&poolId=${pool.id}`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //       },
  //     })

  //     if (response.ok) {
  //       const data = await response.json()
  //       window.open(data.redirectUrl, "_blank")
  //     } else {
  //       setSnackBarErrorMessage("Failed to posses user")
  //     }
  //   } catch (error) {
  //     setSnackBarErrorMessage(`Error: ${(error as any).message}`)
  //   }
  // }

  if (/challenge/.test(pool.gameInstanceUid)) {
    return (
      <div style={{ display: "flex", alignItems: "center", flexFlow: "column" }}>
        <iframe src="https://giphy.com/embed/owRSsSHHoVYFa" width="480" height="360" frameBorder="0" allowFullScreen></iframe>
        <br />
        <span>NOPE - This is a challenge pool.</span>
      </div>
    )
  }

  const adminMembersSortFunc = (a, b) => {
    if (a.member.isManager && !b.member.isManager) {
      return -1
    } else if (!a.member.isManager && b.member.isManager) {
      return 1
    }
    return a.member.name.localeCompare(b.member.name)
  }

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h6" component="h6">
            Score Pool : Re-run Standings
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>Score Full Season</div>
            <Switch checked={scoreFullSeason} onChange={() => setScoreFullSeason(!scoreFullSeason)} value={scoreFullSeason} color={"primary"} />
          </div>
          <br />
          <FormButtonSpinnerBase
            isValid={true}
            as={ButtonSmallBase}
            isSubmitting={loadingScorePool}
            inert="Rerun Standings"
            onClick={(e) => {
              e.preventDefault()
              scorePool(pool.id, scoreFullSeason)
            }}
            disabled={loadingScorePool}
            status={""}
          />
        </CardContent>
      </Card>
      <br />
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h6" component="h6">
            Delete Picks For All Players In Pool And For All Periods
          </Typography>
          <br />
          <FormButtonSpinnerBase
            isValid={true}
            as={ButtonSmallBase}
            isSubmitting={loadingDeletePicks}
            inert="Delete Picks"
            onClick={(e) => {
              e.preventDefault()
              deletePicks(pool.id)
            }}
            disabled={loadingDeletePicks}
            status={""}
          />
        </CardContent>
      </Card>
      <br />
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h6" component="h6">
            Posses User
          </Typography>
          <br />
          <Grid container direction="row">
            <Grid item xs={12}>
              <Grid container direction="row" style={{ alignItems: "center" }} spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">User Name</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle1">Cust ID</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Email</Typography>
                </Grid>
                <Grid item xs={2} style={{ textAlign: "right" }}>
                  <Typography variant="subtitle1">Is Manager</Typography>
                </Grid>
                <Grid item xs={2} style={{ textAlign: "right" }}>
                  <Typography variant="subtitle1">Posses</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "5px" }}>
              {poolMembersDataQuery.loading && (
                <Grid item xs={12}>
                  <LoadingView />
                </Grid>
              )}
              {[...(poolMembersDataQuery.data?.pool.adminMembers ?? [])].sort(adminMembersSortFunc).map((adminMember) => {
                const adminUser = poolMembersUserLoginAdminQuery.data?.sql.find((user) => user.id === Number(adminMember.user.picksDbId))
                const possessUrl =
                  constants.APP_ENV !== "prod"
                    ? `http://www.qa.cbssports.com/private/posses?owner_id=${adminUser?.userLogin}&xurl=${poolMembersDataQuery.data?.pool.url}`
                    : `https://www.cbssports.com/private/posses?owner_id=${adminUser?.userLogin}&xurl=${poolMembersDataQuery.data?.pool.url}`
                return (
                  <Grid item xs={12} key={adminMember.user.picksDbId}>
                    <Grid container direction="row" style={{ alignItems: "center" }} spacing={2}>
                      <Grid item xs={3} style={{ overflow: "auto" }}>
                        {adminMember.member.name}
                      </Grid>
                      <Grid item xs={2}>
                        {adminMember.user.custId}
                      </Grid>
                      <Grid item xs={3} style={{ overflow: "auto" }}>
                        {adminMember.member.email}
                      </Grid>
                      <Grid item xs={2} style={{ textAlign: "right" }}>
                        {adminMember.member.isManager ? "Yes" : "-"}
                      </Grid>
                      <Grid item xs={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                        {adminUser?.userLogin ? (
                          <Link to={possessUrl} external>
                            Posses User
                          </Link>
                        ) : (
                          "Offline User"
                        )}
                      </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

export default OPMPool
